import React from "react";

// Customizable Area Start
import { Box, Grid, CircularProgress, IconButton } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import FavoriteIcon from '@mui/icons-material/Favorite';
// Customizable Area End

import ProductSummaryController, { Props } from "../ProductSummaryController.web";

export default class MainImageSection extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            isLoading,
            product,
            productMainImage,
            wishlistLoader,
        } = this.state;
        return (
            // Customizable Area Start
            <>
                {!isLoading && product &&
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} position={"relative"}>
                        <Box className={`mainImageBox`}>
                            <img style={{ ...{ width: "100%", height: "100%", objectFit: "cover" }, ...product.available_quantity < 1 ? { filter: "grayscale(100%)" } : {} }} src={productMainImage.url} alt="Product image" />
                            {wishlistLoader ?
                                <IconButton className="favoriteButton"> <CircularProgress style={{ color: "#FFF" }} size={20} /></IconButton>
                                :
                                <>
                                    {product.is_wishlisted ?
                                        <IconButton data-test-id="addToWishList" className="favoriteButton">
                                            <FavoriteIcon style={{ color: "#DB2777" }} />
                                        </IconButton>
                                        :
                                        <IconButton data-test-id="addToWishList" onClick={() => this.handleAddToWishlist(product.id)} className="favoriteButton">
                                            <FavoriteBorderIcon />
                                        </IconButton>
                                    }
                                </>
                            }

                        </Box>
                        <Box width={"90%"} height={"100%"} position={"absolute"} top={"0px"}>
                            {productMainImage.id < product.media_files.length &&
                                <IconButton data-test-id="imageNextButton" onClick={() => this.handleLoadNextImage()} className="imageRightArrow"><ArrowForwardIos /></IconButton>
                            }
                            {productMainImage.id !== 1 &&
                                <IconButton data-test-id="imagePreviousButton" onClick={() => this.handleLoadPreviousImage()} className="imageLeftArrow"><ArrowBackIos /></IconButton>
                            }
                        </Box>
                    </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End

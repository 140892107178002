import React from "react";

// Customizable Area Start
import { styled, Container } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import StatSection from "./components/StatSection.web";
import HowItWorkSection from "./components/HowItWorkSection.web";
import WhyChooseUsSection from "./components/WhyChooseSection.web";
import RealWeddingSection from "./components/RealWeddingSection.web";
import VendorSection from "./components/VendorSection.web";
import ShopSection from "./components/ShopSection.web";
import DoNotSection from "./components/DoNotSection.web";
import WhoAreSection from "./components/WhoAreSection.web";
import CarouselSection from "./components/CarouselSection.web";
import ContactUsSection from "./components/ContactUsSection.web";
import NewSletterSection from "./components/NewSletterSection.web";
import FaqSection from "./components/FaqSection.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { state } = this;
    const { carouselSectionTitle, carouselSectionDescription } = state;
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={state.isLoading} />
        <CarouselSection
          data-test-id="CarouselSection"
          carouselItem={state.carouselItem}
          title={carouselSectionTitle}
          description={carouselSectionDescription}
          redirectToVendorPage={this.redirectToVendorPage}
        />
        <CustomContainer maxWidth="lg">
          <StatSection data-test-id="stats-container" experienceData={this.state.experienceData}/>
          <WhoAreSection
            whoWeAreData={state.whoWeAreData}
            data-test-id="who-are"
          />
          <WhyChooseUsSection
            whyChooseUsData={state.whyChooseUsData}
            data-test-id="why-choose-us"
          />
          <HowItWorkSection
            howItWorkData={state.howItWorkData}
            data-test-id="how-it-work"
          />
          <VendorSection data-test-id="vender-team" />
          <ShopSection
            buttonText="Shop all trendings"
            title="Shop"
            description="Stay ahead of the curve with the latest trends and expert tips for planning your perfect Indian wedding. From traditional elements to modern twists, we've got you covered with the best advice to make your special day truly unforgettable."
            data-test-id="shop"
          />
          <ContactUsSection
            data-test-id="contact-us"
            contactUsApiIntegration={this.contactUsApiIntegration}
            formikContactRef={this.formikContactRef}
            subjectList={state.subjectList}
          />
          <RealWeddingSection realWeddingData ={state.realWeddingData}
           realWeddingProfileData={state.realWeddingProfileData}
           redirectToRealWeddingItemsPage = {this.redirectToRealWeddingItemsPage}
           goToRealWeddingPage={this.goToRealWeddingPage}
            data-test-id="real-wedding" />
          <ShopSection
            buttonText="Browse all trendings"
            title="Trending"
            sx={{ padding: "20px 0" }}
            description="Stay ahead of the curve with the latest trends and expert tips for planning your perfect Indian wedding. From traditional elements to modern twists, we've got you covered with the best advice to make your special day truly unforgettable."
            data-test-id="trending"
          />
          <NewSletterSection
            data-test-id="new-sletter"
            newSletterApiIntegration={this.newSletterApiIntegration}
            formikNewSletterRef={this.formikNewSletterRef}
            handleChangeIsEmailAlreadyUsed={this.handleChangeIsEmailAlreadyUsed}
            isEmailAlreadyUsed={state.isEmailAlreadyUsed}
          />
          <DoNotSection data-test-id="do-not-take-word" />
          <FaqSection
            data-test-id="faqs"
            faqQuestionAnswer={state.faqQuestionAnswer}
          />
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End

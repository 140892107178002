import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area Start
interface DropdownData {
  id: number,
  label: string,
  value: string,
}

interface ReviewsResponse {
  id: string;
  type: string;
  attributes: ReviewsAttributes;
}

interface ReviewsAttributes {
  id: number;
  title: string;
  description: string;
  product_id: number;
  account_id: number;
  created_at: string;
  score: number;
  reviewer: {
    data: {
      id: string;
      type: string;
      attributes: ReviewerAttributes;
    };
  };
}

interface ReviewerAttributes {
  full_name: string;
  email: string;
  profile_picture: string;
}

interface ProductReviews {
  id: number,
  title: string,
  comment: string,
  date: string,
  score: number,
  user: ReviewerAttributes,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  productId?: number;
  productName?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  title: string;
  description: string;
  account_id: number;
  anonymous: boolean;
  ReviewsList: [];
  activeCreatedAt: string;
  isVisible: boolean;
  accountReview: any;
  reviewComment: string;
  reviewCommentError: boolean;
  reviewCommentMsg: string;
  reviewRating: number;
  reviewRatingError: boolean;
  reviewRatingMsg: string;
  productReviews: ProductReviews[];
  averageRating: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewsController extends BlockComponent<Props, S, SS> {
  reviewsApiCallId: any;
  deleteReviewApiCallId: any;
  addReviewApiCallId: any;
  updateReviewApiCallId: any;
  getReviewsDataId: string = "";
  addReviewId: string = "";
  getProductAverageRatingId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      isLoading: false,
      title: "",
      description: "",
      account_id: 1,
      anonymous: false,
      token: "",
      ReviewsList: [],
      accountReview: null,
      activeCreatedAt: "",
      isVisible: false,
      reviewComment: '',
      reviewCommentError: false,
      reviewCommentMsg: '',
      reviewRating: 0,
      reviewRatingError: false,
      reviewRatingMsg: '',
      productReviews: [],
      averageRating: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getReviewsData();
    if (this.props.productId) {
      this.getProductAverageRating(this.props.productId);
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getReviewsDataId:
          this.handleReviewsDataResponse(webResponseJson);
          break;

        case this.addReviewId:
          this.handleAddReviewsResponse(webResponseJson);
          break;

        case this.getProductAverageRatingId:
          this.handleProductAverageRatingResponse(webResponseJson);
          break;

        default:
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtTitleProps = {
    onChangeText: (text: string) => {
      this.setState({ title: text });

      //@ts-ignore
      this.txtTitleProps.value = text;
    }
  };

  txtDescriptionProps = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
      //@ts-ignore
      this.txtDescriptionProps.value = text;
    }
  };

  addQuery = () => {
    this.props.navigation.navigate("AddReview");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleCommitInputChange = (event: any) => {
    this.setState({ reviewComment: event.target.value, reviewCommentError: false, reviewCommentMsg: '' });
  }

  handleRatingChange = (event: any) => {
    console.log("rating: ", event.target.value)
    const stars = parseFloat(event.target.value);
    console.log("parsed rating: ", stars)
    this.setState({ reviewRating: stars, reviewRatingError: false, reviewRatingMsg: '' });
  }

  getReviewsData = async () => {
    this.setState({ isLoading: true });
    const { productId } = this.props;
    this.getReviewsDataId = await apiCall({
      endPoint: `${configJSON.reviewRatingEndpoint}?product_id=${productId}`,
      method: configJSON.httpGetMethod,
    });
  }

  handleReviewsDataResponse = (response: { data: ReviewsResponse[], error: string }) => {
    if (response.data) {
      const reviews = response.data.map((item) => {
        const review = item.attributes;
        const reviewer = review.reviewer.data.attributes;
        return {
          id: review.id,
          title: review.title,
          comment: review.description,
          date: review.created_at,
          score: review.score,
          user: reviewer
        }
      });
      this.setState({ productReviews: reviews });
    } else {
      this.setState({ productReviews: [] });
    }
    if (response.error) {
      console.log(response.error);
    }
    this.setState({ isLoading: false });
  }

  handleSubmitReview = async () => {
    const token = await getStorageData("authToken");
    if (!token) {
      toast.error("Please login to add review.");
      return false;
    }

    const { reviewComment, reviewRating } = this.state;
    let isValid = true;
    if (!reviewComment) {
      this.setState({ reviewCommentError: true, reviewCommentMsg: "Comment is required." });
      isValid = false;
    }
    if (reviewRating === 0) {
      this.setState({ reviewRatingError: true, reviewRatingMsg: "Rating is required." });
      isValid = false;
    }
    if (!isValid) {
      return false;
    }

    this.setState({ isLoading: true });
    const { productId } = this.props;
    const requestBody = {
      review: {
        title: 'Product review',
        description: reviewComment,
        score: reviewRating
      }
    }

    this.addReviewId = await apiCall({
      endPoint: `${configJSON.reviewRatingEndpoint}?product_id=${productId}`,
      contentType: 'application/json',
      method: configJSON.httpPostMethod,
      body: JSON.stringify(requestBody),
    });
  }

  handleAddReviewsResponse = (response: any) => {
    if (response.data) {
      this.setState({ reviewComment: '', reviewRating: 0 })
      toast.success("Review added successfully");
      this.getReviewsData();
    }
    if (response.error) {
      toast.error(response.error);
    }
    this.setState({ isLoading: false });
  }

  getProductAverageRating = async (id: number) => {
    this.setState({ isLoading: true });
    this.getProductAverageRatingId = await apiCall({
      endPoint: `${configJSON.productAverageRatingEndpoint}?product_id=${id}`,
      method: configJSON.httpGetMethod,
    });
  };

  handleProductAverageRatingResponse = (response: any) => {
    if (response?.average_score) {
      this.setState({
        averageRating: response.average_score.average_score,
      });
    }
    if (response.error) {
      console.log(response.error);
    }
    this.setState({ isLoading: false });
  };
  // Customizable Area End
}
